import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { PredictionQuestionFormSectionService } from '../../prediction-question-section-form.service';
import { lastValueFrom } from 'rxjs';
import { DialogService } from 'src/app/common/components/_base-component/dialog/dialog.service';
import { RoundHelperService } from '../../round-helper.service';

@Component({
  selector: 'prediction-questions-section',
  templateUrl: './prediction-questions-section.component.html',
  styleUrls: ['./prediction-questions-section.component.scss']
})
export class PredictionQuestionsSectionComponent implements OnInit {

  questionForm: FormGroup;

  constructor(
    private questionFormSectionService: PredictionQuestionFormSectionService,
    private rootFormGroup: FormGroupDirective,
    private roundHelperService: RoundHelperService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.questionForm = this.rootFormGroup.control || this.rootFormGroup.form;
  }

  getFromArray() {
    return (this.questionForm?.controls?.["questions"] as FormArray)?.['controls'] || [];
  }

  addQuestionForm() {
    (this.questionForm.controls["questions"] as FormArray)
      .push(this.questionFormSectionService.buildForm(this.roundHelperService.isMultiEventRound));
  }

  async removeQuestionForm(index: number) {

    const result = await lastValueFrom(
      this.dialogService.openDeleteConfirmationPopup(`this question`)
    );
    if (result) {
      (this.questionForm.controls["questions"] as FormArray).removeAt(index);
    }
  }

  up(index) {
    if (
      (this.questionForm?.controls['questions'] as FormArray).controls[index] &&
      (this.questionForm?.controls['questions'] as FormArray).controls[index-1]
    ) {
      const currentControl = (this.questionForm?.controls['questions'] as FormArray).controls[index];
      (this.questionForm?.controls['questions'] as FormArray)
        .controls[index] = (this.questionForm?.controls['questions'] as FormArray).controls[index-1];
      (this.questionForm?.controls['questions'] as FormArray).controls[index-1] = currentControl;
    } else if(index == 0) {
      const maxIndex = (this.questionForm?.controls['questions'] as FormArray).controls.length - 1;
      const currentControl = (this.questionForm?.controls['questions'] as FormArray).controls[0];
      for(let index = 0; index < maxIndex; index++) {
        (this.questionForm?.controls['questions'] as FormArray).controls[index] =
          (this.questionForm?.controls['questions'] as FormArray).controls[index+1];
      }
      (this.questionForm?.controls['questions'] as FormArray).controls[maxIndex] = currentControl;
    }
  }

  down(index) {
    if (
      (this.questionForm?.controls['questions'] as FormArray).controls[index] &&
      (this.questionForm?.controls['questions'] as FormArray).controls[index+1]
    ) {
      const currentControl = (this.questionForm?.controls['questions'] as FormArray).controls[index];
      (this.questionForm?.controls['questions'] as FormArray)
        .controls[index] = (this.questionForm?.controls['questions'] as FormArray).controls[index+1];
      (this.questionForm?.controls['questions'] as FormArray).controls[index+1] = currentControl;
    } else {
      const maxIndex = (this.questionForm?.controls['questions'] as FormArray).controls.length - 1;
      const currentControl = (this.questionForm?.controls['questions'] as FormArray).controls[maxIndex];
      for(let index = maxIndex; index > 0; index--) {
        (this.questionForm?.controls['questions'] as FormArray).controls[index] =
          (this.questionForm?.controls['questions'] as FormArray).controls[index-1];
      }
      (this.questionForm?.controls['questions'] as FormArray).controls[0] = currentControl;
    }
  }

}
