<wizzard-section>
  <div header>QUESTIONS</div>
  <div class="body" body>
    <ng-container *ngFor="let questionForm of getFromArray(); let index = index">
      <ng-container [formGroup]="questionForm">
          <prediction-question
            (onUp)="up(index)"
            (onDown)="down(index)"
            [isRemoveDisabled]="getFromArray().length > 1"
            [predictionNumber]="index + 1"
            (onRemoveQuestionForm)="removeQuestionForm(index)"
            (onAddQuestionForm)="addQuestionForm()">
          </prediction-question>
      </ng-container>
    </ng-container>
  </div>
</wizzard-section>
