<correct-card xmlns="http://www.w3.org/1999/html" [predictionNumber]="predictionNumber">
  <div card-header class="question-header-container">
    <div class="event-name">{{question ? question.text : event.name}}</div>
    <status-chip *ngIf="difficultyLevel" [status]="difficultyLevel" [chipType]="LabelChipTypeEnum.STREAK_QUESTION">
      {{textService.createNameWithSpaceAndCapitalizeFirstLetter(difficultyLevel)}}
    </status-chip>
  </div>
  <ng-container additional-text>
    <div class="processed-result" *ngIf="question && (question.correctAnswer || voidType)">
      (Processed
      result: {{question.correctAnswer || textService.createNameWithSpaceAndCapitalizeFirstLetter(voidType)}})
    </div>
    <div class="score-description-wrapper" *ngIf="!question">
      <div class="score-description">Please enter this game correct score:</div>
      <i
        class="fa-solid fa-circle-info tooltip"
        [matTooltipPosition]="TooltipPositionEnum.right"
        #tooltip="matTooltip"
        [matTooltip]="'Please make sure you selected the same score for both the round event and the score question'"
        [matTooltipClass]="TooltipPositionEnum.right"></i>
    </div>
  </ng-container>
  <form form [formGroup]="singleQuestionForm">
    <ng-container *ngIf="!question || question.type === QuestionTypesEnum.SCORE; else fixedScore">
      <custom-input
        [type]="'number'"
        [placeholder]="'Enter correct answer for home team'"
        [formControlName]="'homeTeam'">
        {{event.homeTeamSportEvents.name}}
      </custom-input>
      <custom-input
        [placeholder]="'Enter correct answer for away team'"
        [type]="'number'"
        [formControlName]="'awayTeam'">
        {{event.awayTeamSportEvents.name}}
      </custom-input>
    </ng-container>
    <ng-template #fixedScore>
      <custom-select
        [enableDeselect]="true"
        [selectOptions]="scoreOption$ | async"
        [formControlName]="'homeTeam'">
        {{event.homeTeamSportEvents.name}}
      </custom-select>
      <custom-select
        [enableDeselect]="true"
        [selectOptions]="scoreOption$ | async"
        [formControlName]="'awayTeam'">
        {{event.awayTeamSportEvents.name}}
      </custom-select>
    </ng-template>

    <mat-divider *ngIf="question"></mat-divider>
    <custom-radio-button
      *ngIf="question"
      formControlName="voidType"
      [enableDeselect]="true"
      [optionList]="voidOptions">
    </custom-radio-button>
  </form>
  <div warning *ngIf="question && !isMatched && isWarningNeed">No user has this entry</div>
</correct-card>

