import { QuestionAttributeItemDetailsModel } from "./QuestionAttributeItemDetailsModel";
import { QuestionTypesEnum } from "../Enums/QuestionTypesEnum";
import { EventDetailsModel } from "./EventDetailsModel";
import { AnswerDetailsModel } from "./AnswerDetailsModel";

export interface QuestionDetailsModel {
  id: number;
  order: number;
  type: QuestionTypesEnum;
  text: string;
  sportEvent: EventDetailsModel;
  answers: AnswerDetailsModel[];
  attributes: QuestionAttributeItemDetailsModel;
  correctAnswer: string;
  correctAnswerId: number;
  voidType: VoidType;
  isMatched?: boolean;
  maxScoreValue?:string
}

export enum VoidType {
  NO_RESULT = 'NO_RESULT',
  ALL_CORRECT = 'ALL_CORRECT',
  ALL_INCORRECT='ALL_INCORRECT'
}
