import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormArray, FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProfileService } from "../../../../core/services/profile.service";
import {
  BehaviorSubject, catchError, combineLatest, filter, finalize, lastValueFrom,
  of,
  switchMap,
  take, tap, throwError
} from 'rxjs';
import { DialogService } from "../../_base-component/dialog/dialog.service";
import { LocalizationService } from "../../../../core/services/localization.service";
import { LocalesEntityEnum } from "../../../Enums/LocalesEntityEnum";
import { LocalesRequestModel } from "../../../models/LocalesRequestModel";
import { LocaleItem, LocalesResponseModel } from "../../../models/LocalesResponseModel";
import { UserDetailsModel } from "../../../models/UserDetailsModel";
import { SnackBarService } from "../../../../core/services/snack-bar.service";
import { TooltipPositionEnum } from "../../../Enums/TooltipPositionEnum";
import { LocaleItemModel } from '../../../models/LocaleItemModel';

@Component({
  selector: 'round-translation-wizzard',
  templateUrl: './round-translation-wizzard.component.html',
  styleUrls: ['./round-translation-wizzard.component.scss']
})
export class RoundTranslationWizzardComponent implements OnDestroy{

  @ViewChild('container') container: ElementRef;

  isLoaded$ = new BehaviorSubject(false);

  vendorDataFormGroup: FormGroup;

  locales = [];

  closeButtonClicked$ = new BehaviorSubject(false);

  isDisableFillAllButton$ = new BehaviorSubject(false);

  TooltipPositionEnum = TooltipPositionEnum;

  private user: UserDetailsModel;

  constructor(
    public dialogRef: MatDialogRef<RoundTranslationWizzardComponent>,
    private localizationService: LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private profileService: ProfileService,
    private fb: NonNullableFormBuilder,
    private dialogService: DialogService,
    private snackBarService: SnackBarService,
  ) {
    this.vendorDataFormGroup = this.fb.group({
      items: this.fb.array([]),
    });
    this.processLocalizationData();
  }

  ngOnDestroy(): void {
    this.localizationService.detectedLanguage = [];
  }


  async googleTransalate() {
    await lastValueFrom(
      combineLatest(
        [
          this.localizationService.getRoundLocales(this.data.id),
          this.localizationService.vendorLocales$
        ]
      ).pipe(
        switchMap(([localSetting, localData]: [LocalesResponseModel, LocaleItemModel[]]) => {
          const entityToTranslate = this.findEntityToTranslate(localSetting);
          const localeToTranslate = localData.filter(locale => locale.automaticTranslate);
          if (entityToTranslate.length && localeToTranslate.length) {
            const body = this.localizationService.createGoogleTranslateBody(entityToTranslate, localeToTranslate);
            return this.localizationService.getGoogleTranslation({items: body})
              .pipe(
                switchMap((translation) => {
                  const translatedText = []
                  const editedText = []
                  const detectedLocales = []
                  localData.forEach(locale => {
                    const currentLocale = translation.data.find(itemTranslation => itemTranslation.locale === locale.i18n);
                    if (!detectedLocales.length && currentLocale) {
                      this.localizationService.detectedLanguage = currentLocale.translations.map(translation => {
                        return {lang: translation.detectedSourceLanguage}
                      })
                    }

                    if (currentLocale) {
                      entityToTranslate.forEach((entityItem, index) => {
                        this.localizationService.detectedLanguage[index].questionId = entityItem.id;
                        const currentEntityControl = this.formArray.controls.find(control => {
                          return control.get('id').value === entityItem.id
                        })
                        const translationLocaleId = currentEntityControl.get(locale.i18n + 'Id').value
                        if (translationLocaleId) {
                          editedText.push({id: translationLocaleId, text: currentLocale.translations[index].translatedText})
                        } else {
                          const saveLocaleObj: LocalesRequestModel = {
                            localeId: locale.id,
                            text: currentLocale.translations[index].translatedText,
                          }
                          switch (entityItem.entity) {
                            case LocalesEntityEnum.TEAM:
                              saveLocaleObj.teamId = entityItem.id;
                              break;
                            case LocalesEntityEnum.SPORT_EVENT:
                              saveLocaleObj.sportEventId = entityItem.id;
                              break;
                            case LocalesEntityEnum.QUESTION:
                              saveLocaleObj.questionId = entityItem.id;
                              break;
                            case LocalesEntityEnum.ANSWER:
                              saveLocaleObj.answerId = entityItem.id;
                              break;
                            case LocalesEntityEnum.PRIZE:
                              saveLocaleObj.prizeLabelId = entityItem.id;
                          }
                          translatedText.push(saveLocaleObj);
                        }
                      });
                    }

                  });
                  return combineLatest([
                    editedText?.length ? this.localizationService.bulkEditLocale({roundId: this.data.id, items: editedText}) : of(null),
                    translatedText?.length ? this.localizationService.bulkSaveLocale({roundId: this.data.id, items: translatedText}) : of(null)
                  ])
                    .pipe(
                      switchMap(() => {
                        return combineLatest(
                          [
                            this.localizationService.getRoundLocales(this.data.id),
                            this.localizationService.vendorLocales$,
                            of(true)
                          ]
                        );
                      })
                    );
                })
              );
          }
          return of([localSetting, localData, false]);
        }),
        catchError(error => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error)
        }),
        tap(([localSetting, localData, isUpdated]: [LocalesResponseModel, LocaleItemModel[], boolean]) => {
          this.prepareData(localSetting, localData);
          this.checkIfFillAllNeedDisable();
          if (isUpdated) {
            this.snackBarService.showSnackBar('Translations are updated for all round texts');
          } else {
            this.snackBarService.showSnackBar('You dont have language to auto google translation', true);
          }
        }),
        finalize(() => this.isLoaded$.next(true))
      )
    )
  }

  /**
   * Get locale setting from user data and locale setting from current round
   * If round has question text without localized contents, send request to auto google translate
   * Save recieved from google data and recoll api to get all localization info
   * Achived data send to the process function
   * Finally turn of loader
   */
  async processLocalizationData() {
    await lastValueFrom(
      combineLatest(
        [
          this.localizationService.getRoundLocales(this.data.id),
          this.profileService.currentUser$.pipe(take(1)),
          this.localizationService.vendorLocales$
        ]
      ).pipe(
        catchError(error => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error)
        }),
        tap(([localSetting, userData, localData]: [LocalesResponseModel, UserDetailsModel, LocaleItemModel[]]) => {
          this.user = userData;
          this.prepareData(localSetting, localData);
          this.checkIfFillAllNeedDisable();
        }),
        finalize(() => this.isLoaded$.next(true))
      )
    )
  }

  findEntityToTranslate(allRoundData) {
    let entityToTranslate = [];

    entityToTranslate.push(this.getTranslationObj(allRoundData, this.data.isStreak ? LocalesEntityEnum.STREAK : LocalesEntityEnum.ROUND));

    if (allRoundData.questions) {
      allRoundData.questions.forEach(question => {
        if (question.text) {
          entityToTranslate.push(this.getTranslationObj(question, LocalesEntityEnum.QUESTION));
        }

        if (question.answers && question.answers.length) {
          question.answers.forEach(answer => {
            entityToTranslate.push(this.getTranslationObj(answer, LocalesEntityEnum.ANSWER));
          });
        }

        if (question.sportEvent) {
          entityToTranslate = [...entityToTranslate, ...this.getSportEventTranslationObj(question.sportEvent)];
        }
      });
    }

    if (allRoundData.sportEvent) {
      entityToTranslate = [...entityToTranslate, ...this.getSportEventTranslationObj(allRoundData.sportEvent)];
    }

    if (allRoundData.prizeLabels && allRoundData.prizeLabels.length) {
      allRoundData.prizeLabels.forEach(prize => {
        entityToTranslate.push(this.getTranslationObj(prize, LocalesEntityEnum.PRIZE));
      })
    }

    return entityToTranslate;
  }

  getTranslationObj(data, entity) {
    if (entity === LocalesEntityEnum.ANSWER || entity === LocalesEntityEnum.QUESTION || entity === LocalesEntityEnum.PRIZE) {
      const {id, text} = data;
      return {id, text, entity};
    } else {
      const {id, name} = data;
      return {id, name, entity};
    }
  }

  getSportEventTranslationObj(sportEvent) {
    return [
      this.getTranslationObj(sportEvent, LocalesEntityEnum.SPORT_EVENT),
      this.getTranslationObj(sportEvent.awayTeamSportEvents, LocalesEntityEnum.TEAM),
      this.getTranslationObj(sportEvent.homeTeamSportEvents, LocalesEntityEnum.TEAM)
    ];
  }

  /**
   * @param localeRoundSetting - round locale details
   * @param vendorLocale - vendor locale list
   * Init form main setting
   * Call creating of form for each entity
   */
  prepareData(localeRoundSetting, vendorLocale) {
    this.vendorDataFormGroup = this.fb.group({
      items: this.fb.array([]),
    });
    this.locales = this.localizationService.prepareVendorLocales(vendorLocale);
    this.createLocaleObj(localeRoundSetting, this.data.isStreak ? LocalesEntityEnum.STREAK : LocalesEntityEnum.ROUND, vendorLocale);

    if (localeRoundSetting.questions && localeRoundSetting.questions.length) {
      this.prepareQuestionLocales(localeRoundSetting.questions, vendorLocale);
    }

    if (localeRoundSetting.sportEvent) {
      this.prepareSportEventLocales(localeRoundSetting.sportEvent, vendorLocale);
    }

    if (localeRoundSetting.prizeLabels) {
      this.preparePrizeLocales(localeRoundSetting.prizeLabels, vendorLocale);
    }

    this.closeButtonClicked$.next(false);
    const {upsellSourceUrl, localizations} = localeRoundSetting.upsell;
    if (upsellSourceUrl) {
      const upsell = {
        id: this.user.brand.id,
        name: upsellSourceUrl,
        localizations: localizations
      };
      this.createLocaleObj(upsell, LocalesEntityEnum.BRAND_URL, vendorLocale)
    }

    //This is need to prevent scroll to the end of the table
    setTimeout(() => {
      (this.container.nativeElement as HTMLDivElement).scrollTo({
        left: 0,
      });
      // It is work only with timeout
    }, 100);
  }

  /**
   * @param questionLocales - question locale details
   * @param vendorLocale - vendor locale list
   * Go throw each question and create form array which contain form control for each locale
   * In each question go throw all answer and also create form array
   * If in this question present sport event create form array for this event
   */
  prepareQuestionLocales(questionLocales, vendorLocale) {
    questionLocales.forEach(question => {
      if (question.text) {
        this.createLocaleObj(question, LocalesEntityEnum.QUESTION, vendorLocale);
      }
      if (question.answers && question.answers.length) {
        question.answers.forEach(answer => {
          this.createLocaleObj(answer, LocalesEntityEnum.ANSWER, vendorLocale);
        })
      }
      if (question.sportEvent) {
        this.prepareSportEventLocales(question.sportEvent, vendorLocale);
      }
    })
  }

  /**
   * @param sportEvent - event locale details
   * @param vendorLocale - vendor locale list
   * Create form array for this event
   * Create form array for both teams in this
   */
  prepareSportEventLocales(sportEvent, vendorLocale) {
    this.createLocaleObj(sportEvent, LocalesEntityEnum.SPORT_EVENT, vendorLocale);
    this.createLocaleObj(sportEvent.homeTeamSportEvents, LocalesEntityEnum.TEAM, vendorLocale);
    this.createLocaleObj(sportEvent.awayTeamSportEvents, LocalesEntityEnum.TEAM, vendorLocale);
  }

  preparePrizeLocales(roundPrize, vendorLocale){
    roundPrize.forEach(prize => {
      this.createLocaleObj(prize, LocalesEntityEnum.PRIZE, vendorLocale);
    })
  }

  /**
   * @param entityLocaleSetting - locale details for current entity
   * @param entity - entity
   * @param vendorLocale - vendor locale list
   * Create form array, witch contain form control for each vendor locale based on entity
   * Finally form is disabled, becouse all form must be disabled for the biginning
   */
  createLocaleObj(entityLocaleSetting, entity, vendorLocale) {
    if (!this.formArray.value.some(item => item.id === entityLocaleSetting.id && entity === item.entity)) {
      const itemFormGroup = this.localizationService.prepareLocaleObject(entityLocaleSetting, entity, vendorLocale);
      this.formArray.push(itemFormGroup);
      itemFormGroup.disable();
    }
  }

  /**
   * Close wizzard
   * If user dont save some locale will show confirmation popup to approve close if no mark unsaved field with red
   */
  async onClosePrizeWizard() {
    this.closeButtonClicked$.next(true);
    this.vendorDataFormGroup.markAsPristine();
    if (!this.formArray.controls.every(control => control.disabled)) {
      await lastValueFrom(
        this.dialogService.open(
          {
            dialogContent: 'You have unsaved translation. Do you want close without save?',
            labelOk: 'Yes',
            labelNo: 'No'
          }
        ).pipe(
          filter((response) => !!response),
          tap(() => this.dialogRef.close()))
      );
    } else {
      this.dialogRef.close();
    }
  }

  /**
   * Prepare list of columns for table
   */
  getColumns() {
    return this.localizationService.getLocalizationTableColumn(this.locales, ['actions']);
  }

  get formArray() {
    return this.vendorDataFormGroup.get('items') as FormArray;
  }

  /**
   * @param itemFormGroup - form for current row
   * Save all locales and rebuild form
   */
  async onSaveLocaleAction(itemFormGroup) {
    itemFormGroup.markAsUntouched();

    const {items, editedItems} = this.localizationService.createLocaleRequestObject(itemFormGroup, this.locales);

    this.isLoaded$.next(false);
    const request$ = items.length ?
      this.localizationService.bulkSaveLocale({roundId: this.data.id, items}) :
      this.localizationService.bulkEditLocale({roundId: this.data.id, items: editedItems});

    await lastValueFrom(
      request$
        .pipe(
          tap(response => {
            if (items.length) {
              this.updateFormValue(response as LocaleItem[], itemFormGroup);
            }
            this.checkIfFillAllNeedDisable();
          }),
          catchError(error => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return of(error);
          }),
        )
    );

    itemFormGroup.disable();
    this.isLoaded$.next(true);
  }

  updateFormValue(savedLocales: LocaleItem[], form: FormGroup) {
    savedLocales.forEach(locale => form.get(`${locale.locale.i18n}Id`).patchValue(locale.id));
  }

  /**
   * Fill all epty field for all locales for all entity with non localized content
   */

  fillAllLocalesByDefault() {
    this.isLoaded$.next(false);
    const bulkItems = [];
    const bulkEditedItems = [];
    (this.vendorDataFormGroup.get('items') as FormArray).controls.forEach(control => {
      const defaultText = control.get('text').value;
      this.locales.forEach(userLocale => {
        if (!control.get(userLocale.locale).value) control.get(userLocale.locale).patchValue(defaultText);
      })
      const {items, editedItems} = this.localizationService.createLocaleRequestObject(control, this.locales);
      bulkItems.push(...items);
      bulkEditedItems.push(...editedItems);
    })
    this.bulkSaveDefaultItems(bulkItems, bulkEditedItems);
  }

  async bulkSaveDefaultItems(items, editedItems) {
    await lastValueFrom(
      combineLatest([
        this.localizationService.bulkEditLocale({roundId: this.data.id, items: editedItems}),
        this.localizationService.bulkSaveLocale({roundId: this.data.id, items})
      ])
        .pipe(
          switchMap(() => this.processLocalizationData()),
          tap(() => {
            this.snackBarService.showSnackBar('All missing translations are updated with non-localized content');
            this.isDisableFillAllButton$.next(true);
          }),
          catchError(error => {
            this.snackBarService.showSnackBar(error.error.message, true);
            return throwError(error);
          }),
        )
    );
  }

  checkIfFillAllNeedDisable() {
    this.isDisableFillAllButton$.next(
      (this.vendorDataFormGroup.get('items') as FormArray).controls
        .every(control => Object.values(control.value).every(controlValue => controlValue))
    );
  }
}
