import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, firstValueFrom, lastValueFrom, map } from "rxjs";
import { SnackBarService } from 'src/app/core/services/snack-bar.service';
import { QuestionTypesEnum } from 'src/app/common/Enums/QuestionTypesEnum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { QuestionTypeService } from '../common-wizzard-components/prediction-question/question-type.service';
import { AnswerGroupsService } from '../answer-groups.service';
import { MAX_LENGTH_ANSWERS_NOT_OPTION, MAX_LENGTH_ANSWERS_OPTION, PredictionQuestionFormSectionService } from '../prediction-question-section-form.service';
import { RoundHelperService } from "../round-helper.service";

interface CreateAnswerGroupModalData {
  questionType: QuestionTypesEnum,
  answers: [];
  group: any,
  event: number | null,
  //TODO remove 'disableExternalEvent" when the external events functionality will be added
  disableExternalEvent?: boolean
}

interface AnswerGroupForm {
  groupName: FormControl,
  additional?: FormGroup,
  type: FormControl;
}

@Component({
  selector: 'create-asnwer-group-modal',
  templateUrl: './create-answer-group-modal.component.html',
  styleUrls: ['./create-answer-group-modal.component.scss'],
})
export class CreateAnswerGroupModalComponent {

  public groupForm = new FormGroup<AnswerGroupForm>({
    groupName: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(52), Validators.pattern(/.*\S$/)]),
    type: new FormControl('')
  });

  public questionType$ = new BehaviorSubject<QuestionTypesEnum>(undefined);

  public groupNameError$ = new BehaviorSubject<string>('')

  public loading = false;

  maxAnswerLength$ = this.questionType$.pipe(
    map(questionType => questionType === QuestionTypesEnum.OPTIONS ? MAX_LENGTH_ANSWERS_OPTION : MAX_LENGTH_ANSWERS_NOT_OPTION)
  );

  constructor(
    private snackBarService: SnackBarService,
    public answerGroupsService: AnswerGroupsService,
    public questionTypeService: QuestionTypeService,
    public dialogRef: MatDialogRef<CreateAnswerGroupModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CreateAnswerGroupModalData,
    public questionSectionFormService: PredictionQuestionFormSectionService,
    public roundHelperService: RoundHelperService,
  ) {
    this.questionType$.next(data.questionType);
    this.groupForm.get('type').patchValue(data.questionType);
    this.groupForm.get('type').disable();
    if (data.group) {
      this.groupForm.get('groupName').patchValue(data.group['name']);
    }
    if (Array.isArray(data.answers) && data.answers.length > 0) {
      this.groupForm.addControl(
        'additional',
        this.questionSectionFormService.fillAnswersForAnswersGroup(
          data.questionType,
          data.answers,
        )
      );
    } else if (data?.group?.['answers'] && data?.group?.['answers'].length > 0) {
      this.groupForm.addControl(
        'additional',
        this.questionSectionFormService.fillAnswersForAnswersGroup(
          data.questionType,
          data.group['answers'],
        )
      );
    } else {
      this.groupForm.addControl(
        'additional',
        this.questionSectionFormService.buildAdditionnalFormByTypeForAnswerGroups(data.questionType)
      );
    }
  }

  getOptionText(option) {
    return option.name;
  }

  async saveGroup() {
    this.answerGroupsService.existingAnswersId$.next([]);
    try {
      this.loading = true;
      const groupForm = this.groupForm.getRawValue();
      const newGroup = this.data?.group?.['id'] ?
        await lastValueFrom(this.answerGroupsService.updateAnswerGroup({
          name: groupForm.groupName,
          answerIds: groupForm.additional.answers.map(answer => answer.id)
        }, this.data.group['id'])) :
        await lastValueFrom(this.answerGroupsService.createAnswerGroup({
          name: groupForm.groupName,
          answerIds: groupForm.additional.answers.map(answer => answer.id)
        }));
      await firstValueFrom(this.answerGroupsService.getAllAnswerGroups());
      this.answerGroupsService.needUpdateAnswerGroup$.next(true);
      this.snackBarService.showSnackBar(`Group ${newGroup['name']} successfully ${this.data?.group?.['id'] ? 'updated' : 'created'}`);
      this.dialogRef.close({groupId: newGroup['id']})
    } catch (error) {
      this.loading = false;
      if (error.error.statusCode === 409) {
        if (error.error.error === 'ConflictError') {
          this.groupNameError$.next('This group name already exists');
        }
        this.getExistingAnswersId(error.error.message);
      } else {
        this.snackBarService.showSnackBar(error.error.message, true);
      }
    }
    this.loading = false;
  }

  getExistingAnswersId(errorMessage: string) {
    const answersId = JSON.parse(errorMessage.match(/\[(.*?)\]/g)[0]);
    this.answerGroupsService.existingAnswersId$.next(answersId);
  }

  getSingleRoundExternalEvent() {
    const singleRoundEvent = this.roundHelperService.singleRoundEvent;
    return singleRoundEvent?.externalData?.eventId ? singleRoundEvent : null;
  }
}
