import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from "./dialog.component";
import { DialogModel } from "../../../models/DialogModel";
import { filter, take, tap } from "rxjs/operators";
import { RoundWizzardComponent } from "../../../../new-round-wizzard/round-wizzard.component";
import { lastValueFrom } from 'rxjs';
import { RoundTranslationWizzardComponent } from "../../_common-modal-components/round-translation-wizzard/round-translation-wizzard.component";

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  dialogRef: MatDialogRef<any>;

  closeDialogRef: MatDialogRef<DialogComponent>;

  customDialogRef: MatDialogRef<any>;


  constructor(
    public dialog: MatDialog
  ) {
  }

  open(dialogConfig: DialogModel, panelClass = 'custom-dialog-box') {
    const defaultConfig = new DialogModel;
    const config = {...defaultConfig, ...dialogConfig}
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass: panelClass,
    });
    return this.dialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }

  openDeleteConfirmationPopup(deletedItem) {
    const config = {
      ...new DialogModel,
      ...{
        dialogContent: `Are you sure you want to delete ${deletedItem}?`,
        labelOk: 'Yes',
        labelNo: 'No'
      }
    }
    this.closeDialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass: ['custom-dialog-box'],
    });
    return this.closeDialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }

  openRoundWizzard(roundId?) {
    this.customDialogRef = this.dialog
      .open(RoundWizzardComponent, {
        maxHeight: '860px',
        height: '90%',
        width: '660px',
        data: roundId,
        panelClass: ['game-builder'],
        disableClose: true,
      })
  }

  async openLocalizedErrorMessage(round: {id: number}, dialogRef?: MatDialogRef<any>) {
    await lastValueFrom(
      this.open(
        {
          dialogContent: `Some translations are missing for this game. Are you sure you want to continue?`,
          labelOk: 'Go Back to translate',
          labelNo: 'Continue anyway'
        }, 'custom-dialog-box-localization'
      ).pipe(
        tap((response) => {
          this.customDialogRef ? this.customDialogRef.close() : dialogRef.close();
          if (response) {
            this.dialog
              .open(RoundTranslationWizzardComponent, {
                maxHeight: '960px',
                maxWidth: '1600px',
                height:'90%',
                width: '90vw',
                data: round,
                disableClose: true,
                autoFocus: false
              })
          }
        })
      )
    )
  }

  async closeModal() {
    await lastValueFrom(
      this.open(
        {
          dialogContent: 'Are you sure you want to dismiss? Unsaved changes will be deleted.',
          labelOk: 'Yes',
          labelNo: 'No'
        }
      ).pipe(
        filter(response => !!response),
        tap(() => this.customDialogRef.close())
      )
    )
  }

  confirmSlackChangesModal() {
    return this.open(
      {
        dialogContent: 'Are you sure you want to disable Slack notifications? Please note that Slack notifications will be disabled for all vendors.',
        labelOk: 'Yes',
        labelNo: 'No'
      }
    );
  }

  hardCloseModal() {
    this.customDialogRef.close();
  }

  openClearCacheConfirmationPopup() {
    const config = {
      ...new DialogModel,
      ...{
      dialogContent: `Are you sure you want to clear cache?`,
        labelOk: 'Yes',
        labelNo: 'No'
      }
    }
    this.dialogRef = this.dialog.open(DialogComponent, {
      data: config,
      panelClass:['custom-dialog-box'],
    });
    return this.dialogRef.afterClosed()
      .pipe(
        take(1)
      );
  }
}
