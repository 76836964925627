<wizzard-section>
  <div header>GAME DETAILS</div>
  <div body>
    <form [formGroup]="roundDetailsForm">
      <div class="form-header-wrapper">
        <custom-input
          [style.width]="(roundHelperService.isMultiEventRound$ | async) ? '90%' : '100%'"
          placeholder="Name"
          formControlName="name"
          [tooltipsText]="tooltipsTexts.name">
          Game name*
        </custom-input>

        <div *ngIf="roundHelperService.isMultiEventRound$ | async" class="file-loader">
          <i
            class="fa-solid fa-circle-info"
            [matTooltipPosition]="TooltipPositionEnum.above"
            #tooltip="matTooltip"
            [matTooltip]="tooltipsTexts.imageForMultiRound"
            [matTooltipClass]="TooltipPositionEnum.above"></i>
          <small-file-loader
            [image]="{url: roundDetailsForm.value.imageUrl}"
            (click)="prevent($event)"
            (fileUpload)="uploadRoundImage($event)"
            (imageErrorMassage)="showErrorMessage($event)"
          >
          </small-file-loader>
        </div>
      </div>

      <div class="dates-controllers">
        <custom-date-input
          formControlName="openDate"
          [tooltipsText]="tooltipsTexts.openDate">
          Open Date & Time*
        </custom-date-input>
        <custom-date-input
          formControlName="closeDate"
          [tooltipsText]="tooltipsTexts.closeDate">
          Close Date & Time*
        </custom-date-input>
        <mat-icon
          *ngIf="isRoundClosed$ | async"
          (click)="onOpenEditCloseDate.emit()"
          #tooltip="matTooltip"
          [matTooltip]="'Click for edit close date and time'"
          class="material-icons edit edit-close-date">edit</mat-icon>
      </div>

      <custom-select
        [selectOptions]="typeOptionList"
        formControlName="type"
        [tooltipsText]="tooltipsTexts.type">
        Type*
      </custom-select>

      <custom-input
        formControlName="description"
        placeholder="Description"
        [tooltipsText]="tooltipsTexts.description">
        Description*
      </custom-input>

      <drag-and-drop-file-form
        formControlName="backgroundImageDesktopId"
        [tooltipText]="tooltipsTexts?.backgroundImage"
        [url]="roundDetailsForm?.get('backgroundImageDesktopUrl')?.value">
        Background Image Desktop
      </drag-and-drop-file-form>

      <drag-and-drop-file-form
        formControlName="backgroundImageMobileId"
        [tooltipText]="tooltipsTexts?.backgroundImage"
        [url]="roundDetailsForm?.get('backgroundImageMobileUrl')?.value">
        Background Image Mobile
      </drag-and-drop-file-form>

      <custom-select
        *ngIf="isCategory$ | async"
        formControlName="sport"
        placeholder="Choose the option from drop-down"
        tooltipsText="Select one option that best categorizes the content of the round"
        [selectOptions]="sportTypes">
        Category*
      </custom-select>

      <select-create-event
        *ngIf="!(roundHelperService.isMultiEventRound$ | async)"
        formControlName="sportEvent">
      </select-create-event>

    </form>
  </div>
</wizzard-section>
