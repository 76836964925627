import { Component, Input } from "@angular/core";
import { VendorStatusEnum } from "../../../Enums/VendorStatusEnum";
import { RoundStatusEnum } from "../../../Enums/RoundStatusEnum";
import { S3SubmissionStatusEnum } from "../../../Enums/S3SubmissionStatusEnum";
import { LabelChipTypeEnum } from "../../../Enums/LabelChipTypeEnum";
import { StreakQuestionDifficultyEnum } from "../../../Enums/StreakQuestionDifficultyEnum";

@Component({
  selector: 'status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss']
})
export class StatusChipComponent<T> {

  @Input() status: T;

  @Input() customColor: string;

  @Input() chipType: LabelChipTypeEnum;

  getClass() {
    switch (this.chipType) {
      case LabelChipTypeEnum.ROUND_STATUS:
        return this.getRoundLabelColor();
      case LabelChipTypeEnum.S3_STATUS:
        return this.getS3SubmissionLabelColor();
      case LabelChipTypeEnum.STREAK_QUESTION:
        return this.getStreakQuestionLabelColor();
      case LabelChipTypeEnum.VENDOR_STATUS:
        return this.getVendorStatusLabelColor();
      default:
        return '';
    }
  }

  getRoundLabelColor() {
    switch (this.status) {
      case RoundStatusEnum.PENDING:
        return 'on-hold';
      case RoundStatusEnum.COMPLETED:
      case RoundStatusEnum.DRAFT:
        return 'archive';
      case RoundStatusEnum.OPENED:
        return 'active';
      case RoundStatusEnum.CLOSED:
        return 'disabled';
      case RoundStatusEnum.RESULT_PROCESSING:
        return 'waiting';
      default:
        return '';
    }
  }

  getS3SubmissionLabelColor() {
    switch (this.status) {
      case S3SubmissionStatusEnum.SUCCEED:
        return 'active';
      case S3SubmissionStatusEnum.FAILED:
        return 'disabled';
      default:
        return '';
    }
  }
  getStreakQuestionLabelColor() {
    switch (this.status) {
      case StreakQuestionDifficultyEnum.EASY:
        return 'question-easy';
      case StreakQuestionDifficultyEnum.MEDIUM:
        return 'question-medium';
      case StreakQuestionDifficultyEnum.HARD:
        return 'question-hard';
      case StreakQuestionDifficultyEnum.EXTREME:
        return 'question-extreme';
      case StreakQuestionDifficultyEnum.NEAR_CERTAIN:
        return 'question-near-certain';
    }
  }

  getVendorStatusLabelColor() {
    switch (this.status) {
      case VendorStatusEnum.PENDING:
        return 'on-hold';
      case VendorStatusEnum.ACTIVE:
        return 'active';
      case VendorStatusEnum.LOCKED:
        return 'disabled';
      case VendorStatusEnum.VERIFIED:
        return 'waiting';
      default:
        return '';
    }
  }
}
