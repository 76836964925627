import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  BehaviorSubject,
  catchError,
  filter, firstValueFrom,
  lastValueFrom,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError
} from "rxjs";
import {EventsService} from "../../../../core/services/events.service";
import {TeamDetailsModel, CreateTeamDetailsModel} from "../../../models/TeamDetailsModel";
import {NgxMatMomentAdapter} from '@angular-material-components/moment-adapter';
import {NgxMatDateAdapter, NGX_MAT_DATE_FORMATS} from '@angular-material-components/datetime-picker';
import {CreateEventDetailsModel} from "../../../models/CreateEventDetailsModel";
import {SnackBarService} from "../../../../core/services/snack-bar.service";
import {orderBy} from "lodash";
import {APP_DATA} from "../../../../general.app.config";
import {DialogService} from "../../_base-component/dialog/dialog.service";
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

export const CUSTOM_MOMENT_FORMATS = {
  parse: {},
  display: {
    dateInput: 'DD MMM YYYY, HH:MM',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'create-event-modal',
  templateUrl: './create-event-modal.component.html',
  styleUrls: ['./create-event-modal.component.scss'],
  providers: [
    {provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS},
    {provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter},
  ],
})
export class CreateEventModalComponent implements OnInit, OnDestroy {

  isNewItemEntered = false;

  eventDetailsForm: FormGroup;

  private unsubscribe$: Subject<void> = new Subject<void>();

  editedId: number;

  teamsImages: { [key: number]: { id: number, url: string } } = {};

  teamList: TeamDetailsModel[];

  filteredTeamList: TeamDetailsModel[];

  userTeamId = [];

  buttonText = 'Create Event'

  homeTeamName: TeamDetailsModel;

  awayTeamName: TeamDetailsModel;

  appData = APP_DATA;

  showEditTeamRequiredError = false;

  showEditTeamMaxLenghtError = false;

  showEditTeamMinLenghtError = false;

  selectedHomeTeam: any;

  selectedAwayTeam: any;

  header = 'CREATE A NEW EVENT';

  newTeamItemValueEntering = {};

  imageAwayErrorMassege = false;

  imageHomeErrorMassege = false;

  newTeamItemImage = {};

  isShowAnyError = false;

  @ViewChild('homeTeamInput', { read: MatAutocompleteTrigger })
  homeTeamTrigger: MatAutocompleteTrigger;


  @ViewChild('awayTeamInput', { read: MatAutocompleteTrigger })
  awayTeamTrigger: MatAutocompleteTrigger;

  @ViewChild('teamName', {static: false}) teamNameInput: ElementRef;

  teamSaved$ = new BehaviorSubject(true);

  constructor(
    public dialogRef: MatDialogRef<CreateEventModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private eventsService: EventsService,
    private snackBarService: SnackBarService,
    private dialogService: DialogService,
  ) {
  }

  onOpenColorPickerPanel() {
    const pickerElement = document.getElementsByClassName('opened');
    pickerElement[0]?.addEventListener('click', function(event) {
      event.stopPropagation()
    });
  }


  ngOnInit(): void {
    this.eventsService.needUpdateTeamList();
    this.eventsService.getTeamListStatus()
      .pipe(
        filter(respose => !!respose),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.fetchTeamList());

    this.buildForm();

    this.eventDetailsForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => {
        this.userTeamId = [val.awayTeamId.id, val.homeTeamId.id]


        this.filteredTeamList = this.userTeamId.length ?
          this.teamList.filter(team => !this.userTeamId.some(id => id === team.id)) :
          this.teamList;
      })
  }

  fetchTeamList() {
    this.eventsService.getTeamsList()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((teamList: TeamDetailsModel[]) => {
        this.teamList = orderBy(teamList, ['id'], ['asc'])
        this.filteredTeamList = this.userTeamId.length ?
          this.teamList.filter(team => !this.userTeamId.some(id => id === team.id)) :
          this.teamList;

        this.filteredTeamList.forEach(team => {
          const {imageUrl, imageId, id} = team
          this.teamsImages[id] = {url:imageUrl, id:imageId}
        })
        if (this.data) {
          this.header = 'EVENT DETAILS';
          this.fillForm()
        }
      })
  }

  getOptionText(option) {
    return option.name;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm() {
    this.eventDetailsForm = new FormGroup({
      homeTeamId: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(52)]),
      homeTeamImageId: new FormControl(''),
      homeTeamImageUrl: new FormControl(''),
      homeTeamColor: new FormControl(null),
      awayTeamId: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(52)]),
      awayTeamImageId: new FormControl(''),
      awayTeamImageUrl: new FormControl(''),
      awayTeamColor: new FormControl(null),
      name: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(120)]),
      startedAt: new FormControl(new Date(), [Validators.required]),
    })
  }

  get homeTeamColor () {
    return this.eventDetailsForm.get('homeTeamColor') as FormControl
  }

  get awayTeamColor () {
    return this.eventDetailsForm.get('awayTeamColor') as FormControl
  }

  fillForm() {
    this.eventDetailsForm.get('homeTeamId').patchValue(this.data.homeTeamSportEvents);
    this.eventDetailsForm.get('awayTeamId').patchValue(this.data.awayTeamSportEvents);
    this.eventDetailsForm.get('name').patchValue(this.data.name);
    this.eventDetailsForm.get('startedAt').patchValue(this.data.startedAt);
    this.selectedAwayTeam = this.data.awayTeamSportEvents;
    this.selectedHomeTeam = this.data.homeTeamSportEvents;
    this.userTeamId = [this.selectedAwayTeam.id, this.selectedHomeTeam.id];
    this.buttonText = 'Update Event';
    this.homeTeamName = new TeamDetailsModel();
    this.homeTeamName.imageId = this.data.homeTeamSportEvents.imageId;
    this.homeTeamName.imageUrl =  this.data.homeTeamSportEvents.imageUrl;
    this.homeTeamName.color =  this.data.homeTeamSportEvents.color;
    this.awayTeamName = new TeamDetailsModel();
    this.awayTeamName.imageId = this.data.awayTeamSportEvents.imageId;
    this.awayTeamName.imageUrl = this.data.awayTeamSportEvents.imageUrl;
    this.awayTeamName.color = this.data.awayTeamSportEvents.color;
  }

  addNewTeam(event, controlName) {
    const formControlName = controlName + 'Id';
    if (this.showEditTeamMaxLenghtError || this.showEditTeamRequiredError || this.showEditTeamMinLenghtError || this['isShowAnyError' + formControlName]) return;
    event.stopImmediatePropagation();
    const newTeamName = this.eventDetailsForm.get(formControlName).value;
    if (typeof newTeamName !== 'string') return;
    const body: CreateTeamDetailsModel = {
      name: newTeamName,
      color: this.eventDetailsForm.get(`${controlName}Color`).value || null
    }
    if (this.newTeamItemImage[formControlName]) {
      body.imageId = this.newTeamItemImage[formControlName].id;
    }
    this.eventsService.createNewTeam(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
          (this[`${controlName}Trigger`] as MatAutocompleteTrigger).closePanel();
          this.eventDetailsForm.get(formControlName).patchValue(data);
          this.changeTeam(data, controlName);
        },
        error => {
          this.snackBarService.showSnackBar(error.error.message, true)
        })
  }

  onCloseAnswerSelector() {
    this.editedId = null;
    this.showEditTeamMaxLenghtError = false;
    this.showEditTeamMinLenghtError = false;
    this.showEditTeamRequiredError = false;
    this.imageAwayErrorMassege = false;
    this.imageHomeErrorMassege = false;
  }

  onAutocompleteInputEvent(event, formName) {
    this['editing' + formName] = true;
    this.newTeamItemValueEntering[formName + 'Id'] = !!event.srcElement.value;
    if(!event.srcElement.value) {
      this.newTeamItemImage[formName + 'Id'] = null;
      if (this[formName + 'Name']) {
        this[formName + 'Name'].imageId = null;
        this[formName + 'Name'].imageUrl = null;
      }
    }
  }

  isShowDoneEnterIcon(fieldName) {
    const formFieldName = fieldName + 'Id';
    return this.eventDetailsForm.get(formFieldName).value && this.newTeamItemValueEntering[formFieldName] && this['editing' + fieldName];
  }

  prevent(e) {
    e.stopPropagation();
  }

  saveTeam(event, input, teamDefinition) {
    if (this.showEditTeamMaxLenghtError || this.showEditTeamRequiredError || this.showEditTeamMinLenghtError) return;

    if (event) {
      event.stopImmediatePropagation();
    }

    const body: CreateTeamDetailsModel = {
      name: input,
      imageId: this.teamsImages[this.editedId]? this.teamsImages[this.editedId].id : null,
      color: this[`${teamDefinition}Color`].value || null
    }
    this.eventsService.updateTeam(body, this.editedId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
          this.teamSaved$.next(true)

          this.eventsService.needUpdateTeamList();
          this.snackBarService.showSnackBar('Team saved successfully!')

          this.editedId = null;
        },
        error => {
        this.snackBarService.showSnackBar(error.error.message, true)
        })
  }

  async editTeam(event, team, teamName) {
    event.stopPropagation();
    if (this.editedId) {
      this.teamSaved$.next(false)
      this.saveTeam(null, this.teamNameInput.nativeElement.value, teamName);
    }
    await firstValueFrom(this.teamSaved$.pipe(
      filter(val => val)
    ));
    this[`${teamName}Color`].patchValue(team.color);
    this.imageAwayErrorMassege = false;
    this.imageHomeErrorMassege = false;
    this.editedId = team.id;
  }

  async deleteTeam(event, team) {
    event.stopPropagation();
    await lastValueFrom(
      this.dialogService.openDeleteConfirmationPopup(`${team.name} team`)
      .pipe(
        switchMap((response) => {
          if (response) {
            return this.eventsService.deleteTeamById(team.id)
              .pipe(
                tap(() => this.eventsService.needUpdateTeamList()))
          } else {
            return of(null);
          }
        }),
        catchError((error) => {
          this.snackBarService.showSnackBar(error.error.message, true);
          return throwError(error);
        })
      )
    );
  }

  closeDialog() {
    this.dialogRef.close(true);
  }

  saveEvent() {
    if (this.eventDetailsForm.invalid) {
      this.eventDetailsForm.markAllAsTouched();
      return;
    }
    if (this.showEditTeamMaxLenghtError || this.showEditTeamMaxLenghtError || this.showEditTeamMinLenghtError) {
      this.eventDetailsForm.markAllAsTouched();
      return;
    }
    const eventBody = new CreateEventDetailsModel();
    const eventValue = this.eventDetailsForm.value;
    eventBody.name = eventValue.name;
    eventBody.homeTeamId = eventValue.homeTeamId.id;
    eventBody.awayTeamId = eventValue.awayTeamId.id;
    eventBody.startedAt = typeof eventValue.startedAt === 'string' ? eventValue.startedAt : eventValue.startedAt.toISOString();
    const request$ = this.data ?
      this.eventsService.updateEvent(this.data.id, eventBody) :
      this.eventsService.createNewEvent(eventBody);

    request$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.eventsService.needUpdateEventList()
          this.dialogRef.close(true);
        },
        error => this.snackBarService.showSnackBar(error.error.message, true))
  }

  createEventName(homeTeam, awayTeam) {
    const eventName = homeTeam + ' vs ' + awayTeam;
    this.eventDetailsForm.get('name').patchValue(eventName);
  }

  changeTeam(teamValue, fildName) {
    this[fildName + 'Name'] = teamValue;
    if (teamValue.color) {
      this[`${fildName}Color`].patchValue(teamValue.color);
    }
    this.newTeamItemValueEntering[fildName + 'Id'] = false
    if (this.homeTeamName?.name && this.awayTeamName?.name) {
      this.createEventName(this.homeTeamName.name, this.awayTeamName.name);
    }
  }


  /**
   * Control showing error
   */
  isShowError(fieldName, errorName) {
    if (!this.eventDetailsForm.get(fieldName).touched) return false;
    return this.eventDetailsForm.get(fieldName).hasError(errorName);
  }

  onTeamInputChange(e) {
    //eslint-disable-next-line
    this.showEditTeamRequiredError = !(!!e.value);
    this.showEditTeamMaxLenghtError = e.value.length > 52;
    this.showEditTeamMinLenghtError = e.value ? e.value.trim().length < 2 && e.value.trim().length > 0 : false;
  }

  clearTeam(name, event) {
    event.stopImmediatePropagation();

    this.eventDetailsForm.get(name).patchValue(null);
  }

  isShowAddingError(name) {
    this['isShowAnyError' + name] = this.eventDetailsForm.get(name).value.length > 52;
    return this.eventDetailsForm.get(name).value.length > 52;
  }

  getEventTeamAsset (homeTeamName) {
    return homeTeamName?.imageId || homeTeamName?.color
  }

  uploadAnswerImage(image, teamName?) {
    if (teamName) {
      this.newTeamItemImage[teamName] = image;
    } else {
      this.teamsImages[this.editedId] = image;
    }
    event.stopPropagation();
  }

  isShowColorPicker(teamName) {
    return (this.isShowDoneEnterIcon(teamName) || this.eventDetailsForm.get(`${teamName}Color`).value) &&
      !this.newTeamItemImage[`${teamName}Id`];
  }

}
