<correct-card [predictionNumber]="predictionNumber">
  <div card-header class="question-header-container">
    <div class="event-name">{{question.text}}</div>
    <status-chip *ngIf="difficultyLevel" [status]="difficultyLevel" [chipType]="LabelChipTypeEnum.STREAK_QUESTION">
      {{textService.createNameWithSpaceAndCapitalizeFirstLetter(difficultyLevel)}}
    </status-chip>
  </div>
  <div
    additional-text
    *ngIf="question.correctAnswerId || voidType"
    class="processed-result">
    (Processed result: {{getProcessedResult(question.correctAnswerId)}})
  </div>
  <form form [formGroup]="singleQuestionForm">
    <custom-radio-button
      formControlName="answerId"
      [enableDeselect]="true"
      [isVertical]="true"
      [isValueNumber]="true"
      [optionList]="getListOptions(question.answers)">
    </custom-radio-button>
    <mat-divider></mat-divider>
    <custom-radio-button
      formControlName="voidType"
      [enableDeselect]="true"
      [optionList]="voidOptions">
    </custom-radio-button>
  </form>
  <div warning *ngIf="!isMatched && isWarningNeed">No user has this entry</div>
</correct-card>
