export enum LocalesEntityEnum {
  ROUND = 'ROUND',
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER',
  SPORT_EVENT = 'SPORT_EVENT',
  TEAM = 'TEAM',
  BRAND_URL = 'BRAND_URL',
  PRIZE = 'PRIZE',
  LEADERBOARD = 'LEADERBOARD',
  STREAK = 'STREAK'
}
