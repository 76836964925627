import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from "../../material/material.module";
import {AppHeaderComponent} from './app-header/app-header.component';
import {DragAndDropFileComponent} from './_base-component/drag-and-drop-file/drag-and-drop-file.component';
import {BrandSidenavComponent} from "./brand-sidenav/brand-sidenav.component";
import {ImageWithLoaderComponent} from "./_base-component/image-with-loader/image-with-loader.component";
import {DialogComponent} from './_base-component/dialog/dialog.component';
import {CreateEventModalComponent} from './_common-modal-components/create-event-modal/create-event-modal.component';
import {PipesModule} from "../modules/pipes/pipes.module";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {DirectivesModule} from "../modules/directives/directives.module";
import { CreateVendorModalComponent } from './_common-modal-components/create-vendor-modal/create-vendor-modal.component';
import { EditUserModalComponent } from './_common-modal-components/edit-user-modal/edit-user-modal.component';
import {SelectFilterComponent} from './_base-component/select-filter/select-filter.component';
import { MatMenuModule } from '@angular/material/menu';
import { StatusChipComponent } from './_base-component/status-chip/status-chip.component';
import { FormContainerComponent } from './form-container/form-container.component';
import { SmallFileLoaderComponent } from './_base-component/small-file-loader/small-file-loader.component';
import { RoundTranslationWizzardComponent } from './_common-modal-components/round-translation-wizzard/round-translation-wizzard.component';
import { HealthCheckResultModalComponent } from './_common-modal-components/health-check-result-modal/health-check-result-modal.component';
import { AddNewLocaleComponent } from './_common-modal-components/add-new-locale/add-new-locale.component';
import InputComponent from './_base-component/input/input.component';
import { MainModalComponent } from './main-modal/main-modal.component';
import { ModalHeaderComponent } from './main-modal/modal-header/modal-header.component';
import { MatButtonModule } from '@angular/material/button';
import { TabsComponent } from "./tabs/tabs.component";
import { SelectCreateEventComponent } from './_base-component/select-create-event/select-create-event.component';
import SelectComponent from './_base-component/select/select.component';
import DateInputComponent from './_base-component/date-input/date-input.component';
import { DragAndDropFileFormComponent } from './_base-component/drag-and-drop-file-form/drag-and-drop-file-form.component';
import { OWL_DATE_TIME_FORMATS, OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { TwoFAPopupComponent } from "./_common-modal-components/two-fa-popup/two-fa-popup.component";
import { CreateGameFromCsvModalComponent } from "./_common-modal-components/create-game-from-csv-modal/create-game-from-csv-modal.component";
import { CreateLeaderboardComponent } from './_common-modal-components/create-leaderboard/create-leaderboard.component';
import { LeaderboardTranslationModalComponent } from './_common-modal-components/leaderboard-translation-modal/leaderboard-translation-modal.component';
import { HelpPopupComponent } from "./_common-modal-components/help-popup/help-popup.component";
import { NgxEditorModule } from "ngx-editor";
import { NgxColorsModule } from "ngx-colors";
import { EditCmsLabelComponent } from './_common-modal-components/edit-cms-label/edit-cms-label.component';
import { HtmlEditorTranslationsComponent } from './html-editor-translations/html-editor-translations.component';
import { InfoPopupFormModalComponent } from "./_common-modal-components/info-popup-form-modal/info-popup-form-modal.component";
import { DiffReportComponent } from "./_common-modal-components/diff-report/diff-report.component";
import { CreateStreakQuestionComponent } from "./_streak/create-streak-question/create-streak-question.component";
import {
  SelectCreateGroupComponent
} from "../../new-round-wizzard/common-wizzard-components/select-create-group/select-create-group.component";
import {
  AnswersQuestionComponent
} from "../../new-round-wizzard/common-wizzard-components/answers-question/answers-question.component";
import {
  QuestionAutocompleteComponent
} from "../../new-round-wizzard/common-wizzard-components/question-autocomplete/question-autocomplete.component";
import { CreateStreakComponent } from './_streak/create-streak/create-streak.component';
import { EditStreakRoundComponent } from "./_streak/edit-streak-round/edit-streak-round.component";
import { CellWithLabelComponent } from './_base-component/cell-with-label/cell-with-label.component';
import { CreateStreaksLeaderboardComponent } from "./_streak/create-streaks-leaderboard/create-streaks-leaderboard.component";
import { LevelPointsGroupComponent } from './_streak/create-streak/level-points-group/level-points-group.component';
import { WizzardMassageComponent } from "./_base-component/wizzard-massage/wizzard-massage.component";
import { CardComponent } from "./_correct-answers/card/card.component";
import {
	RightListAnswerComponent
} from "./_correct-answers/right-answers/right-list-answer/right-list-answer.component";
import {
	RightRangeAnswerComponent
} from "./_correct-answers/right-answers/right-range-answer/right-range-answer.component";
import {
	RightScoreAnswerComponent
} from "./_correct-answers/right-answers/right-score-answer/right-score-answer.component";
import RadioButtonComponent from "./_base-component/radio-button/radio-button.component";
import { CreateTierColorComponent } from "./_streak/create-tier-color/create-tier-color.component";
import { UpdateUserDifficultyLevelComponent } from './_streak/update-user-difficulty-level/update-user-difficulty-level.component';
import {
  SelectLeaderboardTypeModalComponent
} from "./_common-modal-components/create-leaderboard/select-leaderboard-type-modal/select-leaderboard-type-modal.component";
import {
  CreateSpecialLeaderboardComponent
} from "./_common-modal-components/create-leaderboard/create-special-leaderboard/create-special-leaderboard.component";

export const MY_MOMENT_FORMATS = {
  parseInput: 'DD MMM YYYY',
  fullPickerInput: 'D MMM YYYY, HH:mm',
  datePickerInput: 'DD MMM YYYY',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@NgModule({
	declarations: [
		AppHeaderComponent,
		DragAndDropFileComponent,
		BrandSidenavComponent,
		ImageWithLoaderComponent,
		DialogComponent,
		CreateEventModalComponent,
		CreateVendorModalComponent,
		EditUserModalComponent,
		SelectFilterComponent,
		StatusChipComponent,
		FormContainerComponent,
		SmallFileLoaderComponent,
		RoundTranslationWizzardComponent,
		HealthCheckResultModalComponent,
		AddNewLocaleComponent,
		InputComponent,
		SelectComponent,
		SelectCreateEventComponent,
		SelectComponent,
		DragAndDropFileFormComponent,
		DateInputComponent,
		MainModalComponent,
		ModalHeaderComponent,
		TabsComponent,
		TwoFAPopupComponent,
		CreateGameFromCsvModalComponent,
		CreateLeaderboardComponent,
		LeaderboardTranslationModalComponent,
		HelpPopupComponent,
		EditCmsLabelComponent,
		HtmlEditorTranslationsComponent,
		InfoPopupFormModalComponent,
		DiffReportComponent,
		CreateStreakQuestionComponent,
		SelectCreateGroupComponent,
		AnswersQuestionComponent,
		QuestionAutocompleteComponent,
		CreateStreakComponent,
		LevelPointsGroupComponent,
		EditStreakRoundComponent,
		CellWithLabelComponent,
		CreateStreaksLeaderboardComponent,
		WizzardMassageComponent,
		CardComponent,
		RightListAnswerComponent,
		RightRangeAnswerComponent,
		RightScoreAnswerComponent,
		RadioButtonComponent,
    CreateTierColorComponent,
    UpdateUserDifficultyLevelComponent,
    SelectLeaderboardTypeModalComponent,
    CreateSpecialLeaderboardComponent
	],
	exports: [
		AppHeaderComponent,
		DragAndDropFileComponent,
		BrandSidenavComponent,
		ImageWithLoaderComponent,
		SelectFilterComponent,
		StatusChipComponent,
		FormContainerComponent,
		SmallFileLoaderComponent,
		MainModalComponent,
		ModalHeaderComponent,
		TabsComponent,
		InputComponent,
		SelectCreateEventComponent,
		SelectComponent,
		DateInputComponent,
		SelectComponent,
		DragAndDropFileFormComponent,
		TwoFAPopupComponent,
		CreateGameFromCsvModalComponent,
		HelpPopupComponent,
		SelectCreateGroupComponent,
		AnswersQuestionComponent,
		QuestionAutocompleteComponent,
		CellWithLabelComponent,
		WizzardMassageComponent,
		CardComponent,
		RightListAnswerComponent,
		RightRangeAnswerComponent,
		RightScoreAnswerComponent,
		RadioButtonComponent,
    SelectLeaderboardTypeModalComponent,
    CreateSpecialLeaderboardComponent
	],
	imports: [
		CommonModule,
		MaterialModule,
		PipesModule,
		NgxMatDatetimePickerModule,
		NgxMatTimepickerModule,
		NgxMatNativeDateModule,
		MatDatepickerModule,
		MatNativeDateModule,
		DirectivesModule,
		MatMenuModule,
		MatButtonModule,
		OwlDateTimeModule,
		OwlMomentDateTimeModule,
		NgxEditorModule,
		NgxColorsModule,
	],
    providers: [
        {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
    ],
})
export class CommonComponentsModule {
}
