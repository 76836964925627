import { Injectable } from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators
} from "@angular/forms";
import { CreateRoundPrizeFormInterface } from "../../../common/FormsModels/create-round-prize-form-interface";
import { BehaviorSubject, lastValueFrom, take, tap } from "rxjs";
import { SelectOption } from "../../../common/components/_base-component/select/select.component";
import { VoidType } from "../../../common/models/QuestionDetailsModel";
import { RoundPrizeTypeEnum } from "../../../common/Enums/RoundPrizeTypeEnum";
import { RoundHelperService } from "../../round-helper.service";
import { RoundStatusEnum } from "../../../common/Enums/RoundStatusEnum";
import { RoundPrizeAssigmentEnum } from "../../../common/Enums/RoundPrizeAssigmentEnum";
import { CorrectAnswerHelperService } from "../../../core/services/streak/correct-answer-helper.service";

@Injectable()
export class PredictionPrizeSectionService {

  roundPrizeForm: FormGroup<CreateRoundPrizeFormInterface>;

  questionCount$ = new BehaviorSubject<SelectOption[]>([]);

  isAddingAvailable$ = new BehaviorSubject<boolean>(true);

  isDeleteAvailable$ = new BehaviorSubject<boolean>(false);

  isAnyActionAvailable$ = new BehaviorSubject<boolean>(false);

  isPrizeValid$ = new BehaviorSubject<boolean>(false);

  roundPrizeType$ = new BehaviorSubject<string>(RoundPrizeTypeEnum.CASH_POINTS);


  constructor(
    private fb: NonNullableFormBuilder,
    private roundHelperService: RoundHelperService,
    private correctAnswerHelperService: CorrectAnswerHelperService
  ) {
    this.initControls();
  }

  async initControls() {
    await lastValueFrom(
      this.questionCount$
        .pipe(
          tap((count) => {
            this.isAnyActionAvailable$.next(count.length > 1);
            this.isAddingAvailable$.next(count.length > 1 && count.length > this.prizes.controls.length);
          })
        )
    );
  }


  prepareQuestionCount(questionNumberList, prizes?) {
    questionNumberList = [0, ...questionNumberList]
    return questionNumberList.map(number => {
      const disabled = prizes ? Object.keys(prizes).includes(number): false;
      return {value: number.toString(), label: number.toString(), disabled}
    });
  }

  generateQuestionList(questionList) {
    const questionNumberList = questionList.questions
      .filter(question => question.id)
      .map((question, index) => index + 1)
    const questionCount = this.prepareQuestionCount(questionNumberList).length ? this.prepareQuestionCount(questionNumberList) : this.questionCount$.value;
    this.questionCount$.next(
      questionCount
    );
    this.checkOverlap();
  }

  async processingPrizeForm() {
    this.roundPrizeForm = this.fb.group({
      prizes: this.fb.array([this.createFormItem()]),
    });
    this.isPrizeValid$.next(this.prizes.valid);
    this.statusValidate()

    await lastValueFrom(
      this.prizes.valueChanges
        .pipe(
          tap((formValue) => {
            if (this.roundHelperService.roundStatus && this.isClosedRound()) {
              this.isPrizeValid$.next(this.validPrizeForm());
            }
            const questionCount = this.questionCount$.value;
            questionCount.forEach(question => {
              question.disabled = !!formValue.find(value => value.questionCount === question.value)
            })
            this.questionCount$.next(questionCount);
            this.isAnyActionAvailable$.next(questionCount.length > 1);
            this.isAddingAvailable$.next(questionCount.length > 1 && questionCount.length > this.prizes.controls.length);
            this.isDeleteAvailable$.next(questionCount.length > 1 && this.prizes.controls.length > 1);
          })
        )
    );
  }

  async statusValidate() {
    await lastValueFrom(
      this.prizes.statusChanges.pipe(
        tap(() => {
          this.isPrizeValid$.next(this.prizes.valid)
        })
      )
    )
  }

  isClosedRound() {
    return this.roundHelperService.roundStatus === RoundStatusEnum.RESULT_PROCESSING ||
    this.roundHelperService.roundStatus === RoundStatusEnum.COMPLETED ||
    this.roundHelperService.roundStatus === RoundStatusEnum.CLOSED;
  }

  validPrizeForm() {
    const isAllIncorrect = this.correctAnswerHelperService.questionsForm.value
      .every(value => value.voidType ===  VoidType.ALL_INCORRECT);

    return isAllIncorrect ?
      true :
      this.prizes.controls
        .some(control => control.valid);
  }

  processExistingPrize(prize, questions) {
    const questionCount = this.prepareQuestionCount(
      questions
        .map((question, index) => (index + 1).toString()), prize
    );
    this.questionCount$.next(questionCount);
    if (prize) {
      this.fillForm(prize);
    }
    this.isPrizeValid$.next(this.prizes.valid);
  }


  fillForm(prizes: any) {
    const prizeArray = Object.keys(prizes);
    if (prizeArray.length > 1) {
      for (let i = 0; i < prizeArray.length - 1; i++) {
        this.addFormControl();
      }
    }

    prizeArray.forEach((questionNumber, index) => {
      const form = this.prizes.at(index) as FormGroup;
      form.get('questionCount').patchValue(questionNumber);
      if(prizes[questionNumber].prizeLabelId) {
        form.get('prizeLabelId').patchValue(prizes[questionNumber].prizeLabelId);
      }
      if (this.roundPrizeType$.value === RoundPrizeTypeEnum.CASH_POINTS) {
        form.get('cashPoints').patchValue(prizes[questionNumber].reward);
      } else {
        form.get('prize').patchValue(prizes[questionNumber].reward);
      }
      if(prizes[questionNumber].assignment) {
        form.get('assignment').patchValue(prizes[questionNumber].assignment === RoundPrizeAssigmentEnum.SPLIT);
      }

      form.updateValueAndValidity();
    })
  }

  createFormItem(): FormGroup {
    const formGroup = this.fb.group({
      questionCount: new FormControl(null, [Validators.required]),
      //eslint-disable-next-line
      cashPoints: new FormControl(null, [ Validators.max(1000000000), Validators.min(0), Validators.pattern(/^-?[0-9][^\.\-\+]*$/)]),
      prize: new FormControl(null, [ Validators.minLength(2), Validators.maxLength(50)]),
      prizeLabelId: new FormControl(null),
      assignment: new FormControl(null),
    });
    if (this.roundPrizeType$.value === RoundPrizeTypeEnum.CASH_POINTS) {
      formGroup.get('cashPoints').addValidators([Validators.required])
    } else {
      formGroup.get('prize').addValidators([Validators.required])
    }
    return formGroup;
  }

  getForm() {
    return this.roundPrizeForm;
  }

  get prizes(): FormArray {
    return this.roundPrizeForm?.get('prizes') as FormArray;
  }

  addFormControl() {
    this.prizes.push(this.createFormItem());
  }

  removeFormControl(index) {
    this.prizes.removeAt(index);
  }

  async checkOverlap() {
    await lastValueFrom(
      this.questionCount$.pipe(
        take(1),
        tap(questionCount => {
          if (questionCount) {
            const maxValue = Math.max(...this.prizes.controls.map(control => control.value.questionCount));
            if (this.isNeedRemove(maxValue, questionCount)) {
              this.removeOverlap(maxValue);
            }
          }
        })
      )
    )
  }

  isNeedRemove(maxValue, questionCount) {
    return (questionCount && questionCount.length && questionCount.length < this.prizes.controls.length) ||
      (questionCount && maxValue && maxValue > questionCount.length)
  }

  removeOverlap(maxValue) {
    if (!maxValue && this.prizes.controls.length > 1) {
      this.prizes.removeAt(this.prizes.controls.length - 1);
    }
    const index = this.prizes.controls.findIndex(control => {
      return +control.value.questionCount === +maxValue;
    })
    if (!index && this.questionCount$.value.length) {
      const formGroup = this.prizes.at(index) as FormGroup;
      formGroup.patchValue({cashPoints: null, questionCount: null, prize: null});
    }

    if (index >= 0 && this.prizes.controls.length > 1) {
      this.prizes.removeAt(index);
    }
  }

  createPrizeObject(prizeType) {
    const prizes = this.prizes.controls.reduce((acc, item) => {
      const { questionCount, cashPoints, prize, prizeLabelId, assignment, type } = item.getRawValue();
      const finalPrize = prizeType === RoundPrizeTypeEnum.CASH_POINTS ? +cashPoints : prize.toString();
      const finalPrizeLabelId = prizeType === RoundPrizeTypeEnum.CASH_POINTS ? null : prizeLabelId;
      const finalAssignment = assignment ? RoundPrizeAssigmentEnum.SPLIT : RoundPrizeAssigmentEnum.EACH;
      if (finalPrize && questionCount && finalAssignment) {
        acc[questionCount.toString()] = {reward: finalPrize, assignment: finalAssignment, prizeLabelId: finalPrizeLabelId, type: prizeType };
      }

      return acc;
    }, {})
    return prizes;
  }
}
