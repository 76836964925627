<div class="card-container">
  <div class="prediction-number" *ngIf="predictionNumber"> {{ predictionNumber }}</div>
  <div class="wrapper">
    <ng-content class="header" select="[card-header]"></ng-content>
    <ng-content select="[additional-text]"></ng-content>
    <ng-content select="[form]"></ng-content>
    <div class="warning">
      <ng-content select="[warning]"></ng-content>
    </div>
  </div>
</div>
